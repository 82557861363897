import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";

// Register necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CustomBarChart = ({ data, type }) => {
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.35);

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.35);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const labels = Object.keys(data);
  const values = Object.values(data);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          borderDash: [10, 10],
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
    },
    elements: {
      bar: {
        // borderRadius: 10,
      },
    },
  };

  const chartData = {
    labels: labels.map((date) => date),
    datasets: [
      {
        data: values,
        backgroundColor: "#0578FF",
      },
    ],
  };

  return (
    <div style={{ width: chartWidth, paddingBottom: 20 }}>
      <Bar data={chartData} options={options} height={200} />
    </div>
  );
};

export default CustomBarChart;
