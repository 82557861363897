import React, { useEffect } from 'react';
import { Label } from '../../components/Label';
import Header from '../../components/Header';
import Separator from '../../components/Separator';
import { PieChart } from '@mui/x-charts';
import CustomBarChart from '../../components/CustomBarChart';
import { useDispatch, useSelector } from 'react-redux';
import { getServices } from '../../redux/Services/facilitiesService';
import { cameraListState, eventListState, servicesState } from '../../redux/Selectors';
import { getCameraList, getEventList } from '../../redux/Services/eventService';
import Cam from '../../assets/cam.svg'
import Calendar from '../../assets/event.svg';
import SettingsIcon from '../../assets/settings.svg'
import Sidebar from '../../components/Sidebar';
import { container } from '../../utils/commonstyles';
import { useLocation, useNavigate } from 'react-router-dom';

function Home(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [radius, setRadius] = React.useState(100);
  const [skipAnimation, setSkipAnimation] = React.useState(false);
  const { getServicesData } = useSelector(servicesState)
  const { eventListData } = useSelector(eventListState);
  const { getCameraListData } = useSelector(cameraListState);
  const location = useLocation();

  const payload = {
    strSearch: "",
    funName: "",
    camName: "",
    camLoc: "",
    dt: "",
    dtTimeHour: "",
    dtTimeMin: ""
  }

  useEffect(() => {
    dispatch(getServices())
    dispatch(getEventList(payload))
    dispatch(getCameraList())
  }, [])




  const iconimage = (item) => (
    <div style={{ height: 55, borderColor: '#E9E4E5' }}>
      <img
        src={item}
        alt=""
        style={{
          height: '100%',
          background: '#E9E4E5',
        }}
      />
    </div>
  )

  const data = [
    {
      title: 'Total Events',
      count: eventListData.length,
      icon: iconimage(Calendar),
      path: '/events',
      id: 3,
    },
    {
      title: 'Services Added',
      count: getServicesData?.length,
      icon: iconimage(SettingsIcon),
      path: '/services',
      id: 2,
    }, {
      title: 'LIVE CAM',
      count: getCameraListData?.length,
      icon: iconimage(Cam),
      path: '/livecam',
      id: 1,
    }
  ]

  const getLast7Months = () => {
    const months = [];
    const now = new Date();

    for (let i = 0; i < 7; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const month = date.toLocaleString('default', { month: 'short' }); // e.g., "Sep"
      const year = date.getFullYear().toString().slice(-2); // Last two digits of year e.g., "24"
      months.unshift(`${month}-${year}`); // Add to the start of the array
    }

    return months;
  };

  // Function to transform data into the desired format
  const formatDataByMonth = (data) => {
    // Get the last 7 months
    const last7Months = getLast7Months();

    // Initialize result with 0 for each of the last 7 months
    const result = last7Months.reduce((acc, month) => {
      acc[month] = 0; // Initialize each month with 0
      return acc;
    }, {});

    // Process the data and count occurrences for each month
    data.forEach(record => {
      const date = new Date(record.CamTimestamp);
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear().toString().slice(-2);
      const key = `${month}-${year}`;

      if (result[key] !== undefined) {
        result[key] += 1; // Increment count for the month if it's in the last 7 months
      }
    });

    return result;
  };


  const transformedData = getServicesData?.map(item => ({
    value: item.FunctionEvemtCount,  // Map the count to 'value'
    label: item.FunctionName,        // Map the name to 'label'
  }));


  const valueFormatter = (item) => `${item.value}`;

  return (
    <div style={container}>
      <Sidebar section={location?.state?.section || 0} />
      <div
        style={{
          backgroundColor: "white",
          height: '95vh',
          margin: '12px 12px 12px 0px',
          minWidth: "83vw",
          borderRadius: 12,
          alignSelf: "center",
          overflowY: "auto"
        }}
      >
        <Header title={'Home'} />
        <Separator />
        <div style={{ margin: '22px' }}>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {data?.map((item) => {
              return (
                <div style={{
                  height: '100px',
                  width: "25%",
                  borderRadius: 4,
                  backgroundColor: '#E9E4E5',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
                  onClick={() => { navigate(item?.path, { state: { section: item?.id } }) }}
                >
                  <div>
                    <Label
                      fontSize={14}
                      fontWeight={"500"}
                      color={"#000"}
                      title={item?.title}
                    />
                    <Label
                      fontSize={24}
                      fontWeight={"700"}
                      color={"#000"}
                      title={item?.count}
                    />
                  </div>
                  {item?.icon}
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <Separator />
          </div>
          <div style={{ display: "flex", width: "100%", height: "350px" }}>
            <div style={{ width: "50%" }}>
              <Label
                fontSize={20}
                fontWeight={"500"}
                color={"#000"}
                style={{
                  marginTop: 10,
                  marginBottom: 20
                }}
                title={'Detection Type Ratio'}
              />
              <div style={{ alignSelf: "flex-start", }}>
                <PieChart
                  height={400}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                  series={[
                    {
                      data: transformedData,
                      innerRadius: radius,
                      arcLabel: (params) => params.label ?? '',
                      arcLabelMinAngle: 20,
                      valueFormatter,
                    },
                  ]}

                />
              </div>
            </div>
            <div>
              <Label
                fontSize={20}
                fontWeight={"500"}
                color={"#000"}
                style={{
                  marginTop: 10,
                  marginBottom: 20
                }}
                title={'Monthwise Total Detection'}
              />
              <CustomBarChart
                data={formatDataByMonth(eventListData)}
              />
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default Home;