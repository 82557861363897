// AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./screens/Login/Login";
import Home from "./screens/Home";
import Services from "./screens/Services";
import LiveCam from "./screens/LiveCam";
import Events from "./screens/Events";
import Settings from "./screens/Settings";
import ServiceDetails from "./screens/Services/ServiceDetails";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/livecam" element={<LiveCam />} />
      <Route path="/services" element={<Services />} />
      <Route path="/events" element={<Events />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/services/service-details" element={<ServiceDetails />} />
    </Routes>
  );
};

export default AppRoutes;
