import axios from "axios";
import { getServicesError, getServicesLoading, getServicesSuccess } from "../Slices/servicesSlice";

const getServices = (payload) => async (dispatch) => {
  dispatch(getServicesLoading(true))
  await axios
    .get("http://103.83.39.56/get_functions")
    .then((response) => {
      dispatch(getServicesSuccess(response.data));
      dispatch(getServicesLoading(false))

    })
    .catch((err) => {
      dispatch(getServicesError(err.response));
      dispatch(getServicesLoading(false))

    });
};

export { getServices };