import styled from "styled-components";
import { styled as MuiStyled } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../theme/index";
import { Pagination } from "@mui/material";
import Select from "@mui/material/Select";
import { breakpoints, muiBreakpoints } from "../../utils/commonstyles";
import { makeStyles } from "@material-ui/core";

const statusStyles = { borderRadius: "4px", padding: "4px 8px" };

export const StyledDiv = styled.div`
  font-size: 10px;
`;

export const WithHeaderMain = styled.div`
  height: 48.35vh;
  width: 100%;
  ${theme.breakpoints.down(muiBreakpoints.mobileM)} {
    width: 100% !important;
  }
  ${theme.breakpoints.down(muiBreakpoints.mobileL)} {
    width: 99%;
  }
`;

export const CustomGrid = MuiStyled(DataGrid)({
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.kiosk.Alabaster,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: "14px !important",
    fontWeight: "bold",
    color: theme.kiosk.inputLabel,
  },
  "& .MuiDataGrid-cellContent": {
    fontSize: "14px !important",
    color: theme.kiosk.tableTextColor,
    fontWeight: "normal",
    [theme.breakpoints.between(muiBreakpoints.mobileS, muiBreakpoints.mobileL)]:
    {
      marginTop: "8px !important",
    },
  },
  "& .custom-total": {
    "& .MuiDataGrid-cellContent": {
      fontSize: "14px !important",
      fontWeight: 600,
      color: theme.kiosk.gravel,
    },
  },
  "& .custom-grand-total": {
    "& .MuiDataGrid-cellContent": {
      fontSize: "16px !important",
      fontWeight: "bold",
      color: theme.kiosk.roman,
    },
  },
  "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
    [theme.breakpoints.between(muiBreakpoints.mobileS, muiBreakpoints.mobileL)]:
    {
      whiteSpace: "unset !important",
      minHeight: "45px !important",
      maxHeight: "45px !important",
    },
  },
});

export const PaginatedGrid = MuiStyled(DataGrid)({
  display: "flex",
  flex: "1",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.kiosk.whisper,
    borderRadius: "16px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: "14px !important",
    fontWeight: "bold",
    color: "white",
  },
  "& .MuiDataGrid-cellContent": {
    fontSize: "14px !important",
    color: theme.kiosk.oxfordBlue,
    fontWeight: "400",
  },
  "& .MuiTablePagination-selectLabel": {
    fontSize: "14px !important",
    color: theme.kiosk.subTitleColor,
    fontWeight: "400",
  },
  "& .MuiDataGrid-footerContainer": {
    display: "block",
    border: "none",
    paddingTop: "8px",
    paddingBottom: "12px",
  },
  "& .MuiDataGrid-row": {
    height: "45px !important",
    maxHeight: "45px !important",
  },
  "& div div div div >.MuiDataGrid-cell": {
    borderBottom: `1px solid ${theme.kiosk.whisper}`,
    height: "45px !important",
    maxHeight: "45px !important",
    padding: "0px !important",
    textWrap: "balance !important",
  },
  "& div div div div >.MuiDataGrid-columnHeader": {
    height: "37px !important",
    maxHeight: "37px !important",
    backgroundColor: "black",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  "&.MuiDataGrid-root": {
    border: "none",
  },
  "& .txnStatus.positive": {
    "& .MuiDataGrid-cellContent": {
      color: theme.kiosk.fern,
    },
  },
  "& .txnStatus.negative": {
    "& .MuiDataGrid-cellContent": {
      color: theme.kiosk.carnation,
    },
  },
  "& .Status.success": {
    "& .MuiDataGrid-cellContent": {
      background: theme.kiosk.aquaSpring,
      color: theme.kiosk.genoa,
      ...statusStyles,
    },
  },
  "& .Status.failed": {
    "& .MuiDataGrid-cellContent": {
      background: theme.kiosk.roseWhite,
      color: theme.kiosk.roman,
      ...statusStyles,
    },
  },
  "& .Status.pending": {
    "& .MuiDataGrid-cellContent": {
      background: theme.kiosk.serenade,
      color: theme.kiosk.orangePeel,
      ...statusStyles,
    },
  },
  "& .Status.other": {
    "& .MuiDataGrid-cellContent": {
      background: theme.kiosk.seaShell,
      color: theme.kiosk.gravel,
      ...statusStyles,
    },
  },
  "& .Mui-checked, & .MuiCheckbox-indeterminate": {
    color: `${theme.kiosk.focusedColor} !important`,
  },
  //@ts-ignore
  "& .MuiDataGrid-columnSeparator--sideRight": {
    visibility: "hidden !important",
  },
});

export const StyledMuiPagination = MuiStyled(Pagination)({
  "& .MuiPaginationItem-root.Mui-selected": {
    background: `linear-gradient(135deg, ${theme.kiosk.blumine} 0%, ${theme.kiosk.outerSpace} 100%)`,
    color: theme.kiosk.white,
    width: "100%",
    height: 40,
    margin: 0,
    borderRadius: 0,
  },
  "& .MuiPaginationItem-root": {
    fontWeight: 400,
    color: theme.kiosk.subTitleColor,
    fontSize: "14px",
  },
  "& .MuiPagination-ul": {
    border: `1px solid ${theme.kiosk.athensGray}`,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiPagination-ul > li": {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    borderRight: `1px solid ${theme.kiosk.athensGray}`,
  },
});

export const StyledPageSelector = MuiStyled(Select)({
  width: 81,
  border: `1px solid ${theme.kiosk.Mischka}`,
  borderRadius: 8,
});

export const StyledTableContainer = styled.table`
  border: 1px solid ${theme.kiosk.iron};
  border-radius: 8px;
  width: 100%;
  padding: 0px 15.75px;
  ${theme.breakpoints.down(muiBreakpoints.mobileM)} {
    width: 309px !important;
    border-radius: 0;
    padding: 0px;
  }
  ${theme.breakpoints.between(muiBreakpoints.mobileM, muiBreakpoints.mobileL)} {
    width: 344px;
    border-radius: 0;
  }
  ${theme.breakpoints.between(muiBreakpoints.mobileL, muiBreakpoints.tablet)} {
    width: 394px;
    border-radius: 0;
  }
`;

export const StyledTableRow = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 41px;
  padding: 0px 12px;
  :last-child {
    border-bottom: none;
    height: fit-content;
    margin: 6px;
    padding-left: 8px;
    overflow-y: auto;
    gap: 8px;
  }
  border-bottom: 1px dotted ${theme.kiosk.inactiveBorder};
`;

export const StyledTableBody = styled.tbody`
  display: flex;
  flex-direction: column;
`;

export const StyledRowColumn = styled.td`
  font-size: ${(props) => (props.bold ? "16px" : "14px")};
  font-weight: ${(props) =>
    props.valueBold ? "bold" : props.bold ? "bold" : "normal"};
  font-family: "Poppins";
  color: ${(props) =>
    props.bold ? theme.kiosk.gravel : theme.kiosk.scarpaFlow};
  text-align: right;
  ${theme.breakpoints.down(muiBreakpoints.tablet)} {
    width: 41%;
    overflow-wrap: break-word;
  }
`;
export const ShowrecordsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

export const RoundedPaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media only screen and (min-width: 0px) and (max-width: ${breakpoints.laptop}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
  }
`;

export const useAccountStyles = makeStyles({
  headerStyle: {
    backgroundColor: theme.kiosk.Alabaster,
    "& .MuiTableCell-root": {
      fontSize: 14,
      fontWeight: 400,
      color: theme.kiosk.mountainMist,
      padding: "8px 16px !important",
    },
  },
  rowStyle: {
    "& .MuiTableCell-root": {
      fontSize: 15,
      fontWeight: 600,
      color: theme.kiosk.gravel,
      padding: "12px 16px !important",
    },
  },
  actionHandler: {
    borderRadius: "4px !important",
    height: 30,
    padding: "8px 16px !important",
    width: "fit-content",
  },
  selectedLabel: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins",
    color: theme.kiosk.genoa,
  },
  checkCircleStyle: { color: theme.kiosk.genoa, marginRight: "4px" },
  selectedContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  totalDetails: {
    display: "flex",
    float: "right",
    gap: "35px",
    marginRight: "24px",
    padding: "8px",
  },
  labelStyle: { margin: "8px" },
  mainDiv: { display: "flex", alignItems: "center" },
});
