import { createSlice } from "@reduxjs/toolkit";

const getServicesInitState = {
  getServicesData: [],
  getServicesError: false,
  isServicesLoading: false
};

const getServicesSlice = createSlice({
  name: "getServices",
  initialState: getServicesInitState,
  reducers: {
    getServicesSuccess: (state, action) => {
      state.getServicesData = action.payload;
      state.getServicesError = false;
    },
    getServicesError: (state, action) => {
      state.getServicesError = action.payload;
      state.getServicesData = [];
    },
    getServicesLoading: (state, action) => {
      state.isServicesLoading = action.payload;
    },
  },
});

export const { getServicesSuccess, getServicesError, getServicesLoading } = getServicesSlice.actions;

export { getServicesSlice };