import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  //custom theme variables
  kiosk: {
    white: "#fff",
    inputValue: "#3c3c46",
    inputLabel: "#5F5E6D",
    inactiveBorder: "#CED4DA",
    disabledColor: "#f4f7f9",
    focusedColor: "#083c4c",
    headerColor: "#303587",
    listBorderColor: "#e6e4e4",
    listItemGradient: "#e0e0f5",
    listBackgroundColor: "#292c3f",
    gray: "#f0f0f0",
    snow: "#fbfbfb",
    lightGray: "#eee",
    vLightGray: "#e8e8e8",
    labelColor: "#B2B1BE",
    listDividerColor: "#EDF1F6",
    tableTextColor: "#3C3C46",
    aliceBlue: "#F6F8FA",
    red: "#FF0000",
    grayTextColor: "#908F97",
    grayVariant: "#808080",
    bellflowerBlue: "#e2e8ef",
    cyanDarkBlue: "#212529",
    mediumPinkRed: "#f06c70",
    darkPinkRed: "#ea6275",
    pinkShade: "#d9adc3",
    veryDarkGray: "#404040",
    mostlyViolet: "#7f00ff",
    mainlyGreen: "#28a746",
    mainlyBlue: "#88959e",
    darkGrayRed: "#5A5A5A",
    darkBlueShade: "#1A2027",
    grayDarkRedShade: "#535252",
    greatDarkBlueShade: "#007bff",
    darkBlueShade2: "#0D6FED",
    greatDarkGrayBlue: "#74788d",
    gray20: "#333",
    chineseSilver: "#CCC",
    black: "#000000",
    subTitleColor: "#6E6E74",
    blue: "#343886",
    darkModerateBlue: "#4A4EA8",
    moderateBlue: "#262A79",
    orange: "#EB752D",
    greyChateau: "#9C9EAE",
    moodyBlue: "#787CC7",
    blueMarguerite: "#6057BB",
    deepCove: "#050847",
    whisper: "#F6F6FA",
    oxfordBlue: "#374957",
    whiteAlpha: "#ffffffe6",
    boxShadowGray: "#d1d2da33",
    midnightBlue: "#080052",
    royalBlue: "#9EB8E9",
    athensGray: "#EBEBF1",
    Mischka: "#D1D2DA",
    Snuff: "#D5D3EB",
    carnation: "#F46166",
    fern: "#56B06F",
    mobster: "#787679",
    portGore: "#083c4c",
    scampi: "#083c4c",
    scarpaFlow: "#5F5E61",
    titanWhite: "#F4F2FF",
    spunPearl: "#ACABAD",
    seaShell: "#F1F1F1",
    mountainMist: "#919093",
    gravel: "#48464A",
    persianRed: "#D52B2D",
    paleState: "#C7C6C7",
    wistful: "#B1AED6",
    BalticSea: "#313034",
    roseWhite: "#FFF7F7",
    mexicanRed: "#AA2224",
    aquaSpring: "#F5FCF9",
    jewel: "#137244",
    genoa: "#19985B",
    bridalHeath: "#FFFCF7",
    pirateGold: "#CC8000",
    orangePeel: "#FFA000",
    Haiti: "#171435",
    Padua: "#A5E5C7",
    PeachOrange: "#FFD999",
    SeaPink: "#EEAAAB",
    BtnSnuff: "#D9D8F0",
    Alabaster: "#FAFAFA",
    roman: "#DD5557",
    iron: "#E2E2E3",
    wildblueYonder: "#8882BD",
    mountainMeadow: "#1FBE72",
    boxShadowscarpaFlow: "#5f5e610d",
    tundora: "#404040",
    deepBlush: "#E68081",
    koromiko: "#FFC666",
    emerald: "#4CCB8E",
    magnolia: "#FDFCFF",
    linkWater: "#EAE8FA",
    gallery: "#EAEAEA",
    denim: "#1976D2",
    blumine: "#175d72",
    outerSpace: "#2c3335",
    paleRose: "#FFD6E9",
    swansDown: "#DFF0E9",
    tallPoppy: "#B52326",
    serenade: "#FFF7E9",
    mirage: "1D2939",
    mountainMeadowNew: "#12B76A",
    oxfordBlueNew: "#344054",
    flamingo: "#F04438",
  },
});
export default theme;
