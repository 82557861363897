import React from 'react'
import { Label } from '../Label';
import LogoutIcon from '../../assets/logout.svg'
import ActiveHome from "../../assets/activeHome.svg";
import Camera from "../../assets/camera.svg";
import ServicesIcon from "../../assets/tools.svg";
import EventsIcon from "../../assets/events.svg";
import Settings from "../../assets/settingsInactive.svg"
import ActiveCamera from "../../assets/activeCamera.svg"
import ActiveServices from "../../assets/activeTools.svg"
import HomeIcon from "../../assets/home.svg"
import ActiveEvent from "../../assets/activeEvent.svg";
import ActiveSettings from "../../assets/activeSettings.svg"
import { container } from '../../utils/commonstyles';
import { replace, useNavigate } from 'react-router-dom';

export default function Sidebar({ section }) {
  const navigate = useNavigate()
  const [selectedSection, setSelectedSection] = React.useState(section || 0);

  return (
    <div style={container}>
      <div className="sidebar" style={styles.sidebar}>
        <div className="logo" style={styles.logo}>
          <img
            style={{
              width: 140,
              height: 50,
              alignSelf: "center",
              margin: 10,
            }}
            src={require("../../assets/logo.png")}
          />
        </div>
        <div style={{ marginBottom: 100 }}>
          <div
            id={0}
            className="menu-item"
            style={{
              ...styles.menuItem,
              backgroundColor: selectedSection === 0 ? "#fff" : "#03045E",
              color: selectedSection === 0 ? "#000" : "#8f84d3",
            }}
            onClick={() => { setSelectedSection(0); navigate('/home', { state: { section: 0 } }) }}
          >
            <img
              src={selectedSection === 0 ? ActiveHome : HomeIcon}
              alt=""
              style={{
                height: 35,
                width: 35
              }}
            />
            <Label fontSize={25} fontWeight={"bold"} title={"Home"} />
          </div>
          <div
            onClick={() => { setSelectedSection(1); navigate('/livecam', { state: { section: 1 } }) }}
            className="menu-item"
            style={{
              ...styles.menuItem,
              backgroundColor: selectedSection === 1 ? "#fff" : "#03045E",
              color: selectedSection === 1 ? "#000" : "#8f84d3",
            }}
          >
            <img
              src={selectedSection === 1 ? ActiveCamera : Camera}
              alt=""
              style={{
                height: 40,
                width: 40
              }}
            />
            <Label fontSize={25} fontWeight={"bold"} title={"LiveCam"} />
          </div>
          <div
            onClick={() => { setSelectedSection(2); navigate('/services', { state: { section: 2 } }) }}
            className="menu-item"
            style={{
              ...styles.menuItem,
              backgroundColor: selectedSection === 2 ? "#fff" : "#03045E",
              color: selectedSection === 2 ? "#000" : "#8f84d3",
            }}
          >
            <img
              src={selectedSection === 2 ? ActiveServices : ServicesIcon}
              alt=""
              style={{
                height: 35,
                width: 35
              }}
            />
            <Label fontSize={25} fontWeight={"bold"} title={"Services"} />
          </div>
          <div
            onClick={() => { setSelectedSection(3); navigate('/events', { state: { section: 3 } }) }}
            className="menu-item"
            style={{
              ...styles.menuItem,
              backgroundColor: selectedSection === 3 ? "#fff" : "#03045E",
              color: selectedSection === 3 ? "#000" : "#8f84d3",
            }}
          >
            <img
              src={selectedSection === 3 ? ActiveEvent : EventsIcon}
              alt=""
              style={{
                height: 35,
                width: 35
              }}
            />
            <Label fontSize={25} fontWeight={"bold"} title={"Events"} />
          </div>
          <div
            onClick={() => { setSelectedSection(4); navigate('/settings', { state: { section: 4 } }) }}
            className="menu-item"
            style={{
              ...styles.menuItem,
              backgroundColor: selectedSection === 4 ? "#fff" : "#03045E",
              color: selectedSection === 4 ? "#000" : "#8f84d3",
            }}
          >
            <img
              src={selectedSection === 4 ? ActiveSettings : Settings}
              alt=""
              style={{
                height: 35,
                width: 35
              }}
            />
            <Label fontSize={25} fontWeight={"bold"} title={"Settings"} />
          </div>
        </div>
        <div
          style={{
            height: 50,
            width: 166,
            backgroundColor: "white",
            alignSelf: "center",
            borderRadius: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 100,
            gap: 18
          }}
        >  <img
            src={LogoutIcon}
            alt=""
            style={{
              height: 38,
              width: 38,
            }}
          />
          <Label
            fontSize={20}
            fontWeight={"400"}
            title={"Log Out"}
            onClick={() => {
              navigate("/", { replace: true });
              window.history.pushState(null, "", "/")
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  )
}


const styles = {
  sidebar: {
    width: "220px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    margin: "20px 0px 0px 35px",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    fontSize: "18px",
    padding: "10px",
  },
  icon: {
    marginRight: "10px",
  },
  logo: {
    marginBottom: "20px",
    padding: "0 20px",
  },
  logoImage: {
    width: "140px",
    Height: "50px",
  },
};
