import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import { container } from '../../utils/commonstyles'
import CamView from '../../components/CamView'
import Header from '../../components/Header'
import Separator from '../../components/Separator'
import { Label } from '../../components/Label'
import { Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getCameraList } from '../../redux/Services/eventService'
import { cameraListState } from '../../redux/Selectors'

export default function LiveCam() {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getCameraListData } = useSelector(cameraListState);


  useEffect(() => {
    dispatch(getCameraList())
  }, [])

  return (
    <div style={container}>
      <Sidebar section={location.state.section} />

      <div
        style={{
          backgroundColor: "white",
          height: '95vh',
          margin: '12px 12px 12px 0px',
          minWidth: "83vw",
          borderRadius: 12,
          alignSelf: "center",
          overflowY: "auto"
        }}
      >
        <Header title={"LiveCam"} />
        <Separator />


        <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", padding: 40, gap: 32 }}>
          {getCameraListData?.map((item) => {
            return (
              <div style={{ display: "flex", flexDirection: "column", width: "30%", }}>
                <Label
                  fontSize={18}
                  fontWeight={"400"}
                  color={"#000"}
                  title={item?.name}
                  style={{ padding: '12px 0px 12px 0px' }}
                />
                <div
                  onClick={() => { }}
                  style={{
                    height: '180px',
                    borderRadius: 10,
                    backgroundColor: 'black',
                    display: "flex",
                    gap: 32,
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 36
                  }}>
                  <img src={`http://103.83.39.56/api/get_event_log_image/${item?.id}`} height={'100%'} width={'100%'} alt="Camera Image" />

                </div>
              </div>
            )
          })}
        </div>

      </div>
    </div>
  )
}
