import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./Reducers"; // Ensure correct import path

// Determine if Redux DevTools should be enabled
const isDevTool = process.env.REACT_APP_WEB_PRODUCTION !== "prod";

// Create the Redux store
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
  devTools: isDevTool,
});

export default store;
