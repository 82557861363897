import SearchIcon from '../../assets/search.svg'
import React, { useEffect, useRef, useState } from 'react'
import { MUIInputBase, Root } from './SearchInputStyled'

const SearchInput = ({
  onSubmit,
  title,
  searchTerm,
  height,
  isDisabled,
}) => {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm)
  const debounceTimeout = useRef(null)

  useEffect(() => {
    setLocalSearchTerm(searchTerm)
  }, [searchTerm])

  useEffect(() => {
    return () => {
      if (debounceTimeout.current !== null) {
        clearTimeout(debounceTimeout.current)
      }
    }
  }, [])
  const handleInputChange = (event) => {
    let newSearchTerm = event.target.value
    if (newSearchTerm.length >= 50) {
      newSearchTerm = newSearchTerm.slice(0, 50)
    }
    setLocalSearchTerm(newSearchTerm)
    if (debounceTimeout.current !== null) {
      clearTimeout(debounceTimeout.current)
    }
    debounceTimeout.current = window.setTimeout(() => {
      onSubmit(newSearchTerm)
    }, 500)
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
  }
  return (
    <>
      <Root height={height} onSubmit={handleFormSubmit}>
        <MUIInputBase
          InputProps={{
            inputProps: { maxLength: 50 },
            startAdornment: (
              <img
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 8,
                  pointerEvents: 'none',
                }}
                src={SearchIcon}
                alt="alt_search_icon"
              />
            ),
          }}
          disabled={isDisabled}
          height={height}
          width={"200px"}
          placeholder={title}
          value={localSearchTerm}
          onChange={handleInputChange}
        />
      </Root>
    </>
  )
}

export { SearchInput }
