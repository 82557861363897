import React from "react";
import {
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useStyles } from "./dropDownStyles";
import { Clear as ClearIcon } from "@mui/icons-material";
import theme from "../../theme/index";
import { makeStyles } from "@material-ui/core";
import { muiBreakpoints } from "../../utils/commonstyles";

const commonErrTxtStyle = {
  color: `${theme.kiosk.carnation} !important`,
  fontWeight: "400 !important",
  fontSize: "12px !important",
  marginLeft: "0 !important",
  marginRight: "0 !important",
  textAlign: "left !important",
};

export const commonStyles = makeStyles(() => ({
  rowSpaceBetween: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftFloatText: {
    textAlign: "left !important",
  },
  errorStyle: {
    ...commonErrTxtStyle,
    marginTop: "4px !important",
  },
  customErrorStyle: {
    ...commonErrTxtStyle,
    marginTop: "-14px !important",
  },
  removeArrowsOnInput: {
    '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button':
    {
      "-webkit-appearance": "none",
      margin: 0,
    },
    '& input[type="number"]': {
      "-moz-appearance": "textfield",
    },
  },
  accountMenuItems: {
    display: "flex !important",
    justifyContent: "space-between !important",
    flexDirection: "row !important",
    borderWidth: "1px 0px !important",
    borderStyle: "solid !important",
    borderColor: `${theme.kiosk.seaShell} !important`,
    [theme.breakpoints.between(
      muiBreakpoints.mobileS,
      muiBreakpoints.mobileL + 1
    )]: {
      fontSize: "12px !important",
    },
    [theme.breakpoints.down(muiBreakpoints.mobileS)]: {
      fontSize: "10px !important",
    },
  },
  accountTypeLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    height: "23px",
    width: "70px",
    minWidth: "fit-content",
    marginLeft: "auto !important",
    backgroundColor: theme.kiosk.seaShell,
    [theme.breakpoints.down(muiBreakpoints.mobileL + 1)]: {
      fontSize: "11px !important",
      width: "45px",
      minWidth: "fit-content",
    },
    [theme.breakpoints.down(muiBreakpoints.mobileS + 1)]: {
      display: "none !important",
    },
  },
  multiLineClass: {
    height: "unset !important",
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
  },
  descError: {
    border: `1px solid ${theme.kiosk.persianRed} !important`,
    fontFamily: "Heebo !important",
    height: "200px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  descStyle: {
    fontSize: "14px",
    fontFamily: "Heebo !important",
    height: "200px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.kiosk.white,
    padding: "24px",
    height: "auto",
    width: "600px",
    borderRadius: "16px",
    [theme.breakpoints.down(muiBreakpoints.tablet)]: {
      width: "100%",
      height: "100%",
      borderRadius: "0px",
    },
  },
  modalCloseIcon: {
    color: theme.kiosk.Mischka,
    alignSelf: "center",
    marginRight: "8px",
  },
  flex_end: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
  },
  otp_msg_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: 4,
  },
  otp_msg_2: {
    cursor: "pointer",
  },
}));
const Dropdown = ({
  label,
  children,
  value,
  onChange,
  rootFormClass,
  selectClass,
  inputClass,
  error,
  errorMsg,
  onBlur,
  errorClass,
  isDisabled,
  onReset,
}) => {
  const classes = useStyles();
  const commonCls = commonStyles();

  return (
    <FormControl
      variant="outlined"
      className={`${classes.listClass} ${rootFormClass}`}
      error={error}
    >
      <InputLabel className={inputClass} id={`${label}-label`}>
        {label}
      </InputLabel>
      <Select
        className={`${classes.listSelectClass} ${classes.customDisable} ${selectClass}`}
        variant="outlined"
        value={value}
        onChange={onChange}
        labelId={`${label}-label`}
        label={label}
        onBlur={onBlur}
        MenuProps={{
          classes: {
            list: classes.listSelector,
          },
        }}
        disabled={isDisabled}
        endAdornment={
          onReset ? (
            <InputAdornment sx={{ marginRight: "10px" }} position="end">
              <IconButton onClick={onReset}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null
        }
      >
        {children}
      </Select>
      {error && (
        <FormHelperText className={`${commonCls.errorStyle} ${errorClass}`}>
          {errorMsg}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { Dropdown };
