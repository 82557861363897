import React from "react";
import { StyledLabel } from "./labelStyles";

export const Label = ({
  color,
  fontSize,
  fontWeight,
  children,
  title,
  textAlign,
  style,
  ...rest
}) => {
  return (
    <StyledLabel
      color="initial"
      style={{
        fontSize,
        fontFamily: "Poppins, sans-serif",
        fontWeight,
        textAlign: "center",
        color: color,
        textAlign: textAlign,
        ...style,
      }}
      {...rest}
    >
      {title}
    </StyledLabel>
  );
};
