import React from 'react';
import { Label } from './Label';

const Header = ({ title }) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      height: 50,
      paddingTop: 18,
      paddingLeft: 18,
      paddingRight: 18
    }}>
      <Label
        fontSize={27}
        fontWeight={"600"}
        color={"#000"}
        title={title}
      />
      <div style={{ display: "flex", gap: "15px" }}>
        <img
          style={{
            width: 26,
            height: 26,
          }}
          src={require("../assets/notification.png")}
        />
        <img
          style={{
            width: 26,
            height: 26,
          }}
          src={require("../assets/user.png")}
        />
        <Label
          fontSize={17}
          fontWeight={"600"}
          color={"#000"}
          title={"Srikant Jena"}
        />
        <img
          style={{
            width: 26,
            height: 26,
          }}
          src={require("../assets/arrowDown.png")}
        />
      </div>


    </div>
  );
}

export default Header;

const styles = {
  icon: {
    fontSize: 18,
  },
}