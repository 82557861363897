import { createSlice } from "@reduxjs/toolkit";

const loginInitState = {
  loginData: [],
  loginError: false,
  isLoading: false,

};

const loginSlice = createSlice({
  name: "login",
  initialState: loginInitState,
  reducers: {
    loginSuccess: (state, action) => {
      state.loginData = action.payload;
      state.loginError = false;
    },
    loginError: (state, action) => {
      state.loginError = action.payload;
      state.loginData = [];
    },
    loginLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { loginSuccess, loginError, loginLoading } = loginSlice.actions;

export { loginSlice };
