import React from 'react'
import Sidebar from '../../components/Sidebar'
import { container } from '../../utils/commonstyles'
import { useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import Separator from '../../components/Separator'

export default function Settings() {

  const location = useLocation()

  return (
    <div style={container}>
      <Sidebar section={location.state.section} />

      <div
        style={{
          backgroundColor: "white",
          height: '95vh',
          margin: '12px 12px 12px 0px',
          minWidth: "83vw",
          borderRadius: 12,
          alignSelf: "center",
          overflowY: "auto"
        }}
      >
        <Header title={"Settings"} />
        <Separator />
      </div>
    </div>
  )
}
