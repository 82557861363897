import React, { useEffect, useState } from "react";
import { colors } from "../../utils/colors";
import backgroundImage from "../../assets/bg.jpg"; // Adjust the path based on your folder structure
import { Label } from "../../components/Label";
import Input from "../../components/Input";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginService } from "../../redux/Services/authService";
import { loginState } from "../../redux/Selectors/index"
import Loader from '../../components/Loader'

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const { isLoading } = useSelector(loginState);

  const onlogin = () => {
    const payload = {
      username: userName,
      password: password
    }
    dispatch(LoginService(payload, navigate));
  }

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: colors.primary,
        backgroundImage: `url(${backgroundImage})`, // Use the imported image
        backgroundSize: "98% 95%", // Ensure the image covers the entire background
        backgroundPosition: "center", // Center the background image
        backgroundRepeat: "no-repeat", // Prevent the background image from repeating
        width: "100vw", // Full viewport width
        height: "100vh", // Full viewport height
        margin: 0,
        padding: 0,
        borderRadius: 20,
        overflow: "hidden"
      }}
    >
      {isLoading && (
        <Loader />
      )}
      <div style={{
        marginLeft: "10%", display: "flex", flexDirection: "column",
        justifyContent: "space-between", borderRadius: 20,
      }}>
        <div style={{ marginTop: "50px" }}>
          <img src={require("../../assets/logo1.png")}
            style={{
              height: '100px', width: "250px", margin: "50px 0px 20px 50px"

            }}
          />
          <Label
            fontSize={27}
            fontWeight={"bold"}
            color={"#000"}
            title={"Welcome to IG AI+ Smart Solutions"}
          />
        </div>
        <div style={{ marginTop: "30px" }}>
          <div
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                width: "150px",
                alignContent: "flex-start",
                display: "flex",
              }}
            >
              <Label
                fontSize={27}
                fontWeight={"300"}
                color={"#000"}
                title={"User Name"}
              />
            </div>
            <div style={{ marginLeft: 20 }}>
              <Input onChange={(e) => setUserName(e.target.value)} width={"250px"} title={"Enter User Name"} />
            </div>
          </div>
          <div
            style={{ marginTop: "30px", display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                width: "150px",
                textAlign: "left",
              }}
            >
              <Label
                fontSize={27}
                fontWeight={"300"}
                color={"#000"}
                title={"Password"}
                textAlign={"left"}
              />
            </div>
            <div style={{ marginLeft: 20 }}>
              <Input onChange={(e) => setPassword(e.target.value)} width={"250px"} />
            </div>
          </div>
          <Button
            variant="text"
            style={{
              backgroundColor: "#004B23",
              color: "white",
              width: 115,
              borderRadius: 12,
              height: 50,
              marginTop: 20,
            }}
            onClick={() => onlogin()}
          >
            Login
          </Button>
          <div onClick={() => console.log("sdfsdf")}>
            <Label
              fontSize={18}
              fontWeight={"700"}
              color={"#D62828"}
              style={{ marginTop: 24, textDecoration: "underline" }}
              title={"Forgot Password ?"}
              textAlign={"left"}
            />
          </div>
        </div>
        <div style={{ display: "flex", marginBottom: '44px' }}>
          <Label
            fontSize={18}
            fontWeight={"300"}
            color={"#888080"}
            style={{ textDecoration: "underline" }}
            title={"Copyright  © 2024 Incite Gravity"}
            textAlign={"left"}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
