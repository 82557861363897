import { makeStyles } from "@material-ui/core";
import theme from "../../theme/index";
import { muiBreakpoints } from "../../utils/commonstyles";

export const useStyles = makeStyles(() => ({
  listClass: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      display: "flex",
      flexDirection: "row",
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Poppins !important",
      color: theme.kiosk.BalticSea,
      [theme.breakpoints.between(
        muiBreakpoints.mobileS,
        muiBreakpoints.mobileL + 1
      )]: {
        fontSize: "12px !important",
      },
      [theme.breakpoints.down(muiBreakpoints.mobileS)]: {
        fontSize: "10px !important",
      },
    },
    "& .MuiInputLabel-root": {
      top: -2,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: `${theme.kiosk.carnation} !important`,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.kiosk.carnation} !important`,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      top: -2,
      color: theme.kiosk.gravel,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.kiosk.wildblueYonder}`,
    },
  },
  customDisable: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: `${theme.kiosk.BalticSea}!important`,
      "-webkit-text-fill-color": `${theme.kiosk.BalticSea}!important`,
    },
  },
  listSelectClass: {
    width: "100%",
    borderRadius: "8px !important",
    height: "42px !important",
  },
  listSelector: {
    padding: "0px !important",
    maxHeight: 48 * 4.5 + 8,
  },
}));
