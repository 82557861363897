export const SOMETHINGWENTWRONGMSG = "Something went wrong, please try again";
export const HEY = "Hey!";
export const LOGINMESSAGE =
    "Log in to access your account and enjoy our services";
export const REMEMBERME = "Remember Me";
export const FORGOTPASSWORD = "Forgot Password?";
export const SIGNIN = "Sign In";
export const PLEASEENTEREMAIL =
    "Please enter your Phone number / Email address.";
export const PLEASEENTERPASSWORD = "Please enter your password.";
export const LOGINFIELDONETEXT = "Phone number / Email Address";
export const LOGINFIELDTWOTEXT = "Password";
export const HOME = "Home";
export const YOURSESSIONEXPIRED =
    "Sorry, your session has expired. Please log in again to continue.";
export const YOUHAVEBEENLOGGEDOUT =
    "You have been logged out successfully. See you again soon!";
export const PROFILEINFO = " Profile Info";
export const YOURDISTRIBUTOR = "Your Distributor:";
export const KIPLDISTRIBUTOR = "KIPL Distributor";
export const YOURREGISTERNUMBER = "Your Register Number:";
export const LOGOUT = "Logout";
export const SESSIONEXPIRED = "Session Expired";
export const OK = "Ok";
export const SESSIONMSG =
    "Your session has expired due to inactivity. Please log in again to continue accessing the services.";
export const MOBILELOGINMSG =
    "Log in to access your account and enjoy our services to make life easier";
export const NOTFOUND = "Not Found";
export const NOTFOUNDMSG =
    "The resource requested could not be found on this server!";
export const SHOW = "Show";
export const RECORDS_PER_PAGE = "records per page";
export const SHOWING = "Showing";
export const OF = "of";
export const RECORDS = "records";
export const TO = "to";
export const GENERAL = "General";
export const USERS = "Users";
export const VIEWUSERS = "View Admin Users";
export const VIEWSTAFF = "View Staff Users";
export const ADDBRANCH = "Add Branch";
export const VIEWBRANCHES = "View Branches";
export const BRANCHES = "Branches";
export const BRANCHNAMEERR = "Branch name is required";
export const BRANCHCODEERR = "Branch code is required";
export const ENTERBRANCHNAME = "Enter branch name";
export const ENTERBRANCHCODE = "Enter branch code";
export const ENTERADDRESS = "Enter address (Optional)";
export const ACTIVATE = "Activate";
export const DEACTIVATE = "Deactivate";
export const ADDADMINUSER = "Add Admin User";
export const ADDSTAFFUSER = "Add Staff User";
export const ENTERFIRSTNAME = "Enter first name";
export const ENTERLASTNAME = "Enter last name";
export const ENTEREMAIL = "Enter email";
export const ENTERMOBILENUMBER = "Enter mobile number";
export const FIRSTNAMEERRMSG = "First name is required";
export const LASTNAMEERRMSG = "Last name is required";
export const EMAILERRMSG = "Email is required";
export const MOBILENUMBER_ERRORMSG = "Mobile number is required";
export const PLEASEENTERVALIDNUMBER_ERRORMSG = "Please enter valid number";
export const YES = "Yes";
export const NO = "No";
export const DOYOUWANTTO = "Do you want to";
export const THEBRANCH = "the Branch?";
export const UPDATEBRANCH = "Update Branch";
export const SUBMIT = "Submit";
export const UPDATEUSER = "Update User";
export const THEUSER = "the User?";
export const RESETPASSWORD = "Reset Password";
export const GOODMORNING = "Good Morning";
export const GOODAFTERNOON = "Good Afternoon";
export const GOODEVENING = "Good Evening";
export const SEARCH = "Search";
export const SEARCHUSER = "Search User";
export const SEARCHBRANCH = "Search Branch";
export const EMAILERRMSG2 =
    "Invalid email format. Please enter a valid email address.";
export const GIFTVOUCHER = "Gift Vouchers";
export const ADDGIFTVOUCHER = "Add Gift Voucher";
export const UPLOADIMAGE = "Upload Image";
export const PROVIDEIMAGEURL = "Provide Image URL";
export const ENTERIMAGEURL = "Enter image URL";
export const BRANDNAMEERR = "Brand name is required";
export const BILLERIDERR = "Biller id is required";
export const PRODUCTNAMEERR = "Product name is required";
export const DESCRIPTIONERR = "Description must be at least 8 characters long.";
export const VALIDITYERR = "Validity is required";
export const TNCERR =
    "Terms and conditions must be at least 8 characters long.";
export const DISCOUNTPERCENTAGEERR = "Discount percenatage is required";
export const DENOMINATIONTYPEERR = "Denomination type is required";
export const MINAMOUNTERR = "Minimum amount is required";
export const MAXAMOUNTERR = "Maximum amount is required";
export const SKUIDERR = "Sku id is required";
export const AMOUNTERR = "Amount is required";
export const IMAGEURLERR = "Image URL is required";
export const ENTERBRANDNAME = "Enter brand name";
export const ENTERBILLERID = "Enter biller id";
export const ENTERPRODUCTNAME = "Enter product name";
export const ENTERVALIDITY = "Enter validity";
export const DESCRIPTION = "Description";
export const TNC = "Terms And Condition";
export const ENTERDISCOUNTPERCENTAGE = "Enter discount percentage";
export const DENOMINATIONTYPE = "Select denomination type *";
export const MINAMOUNT = "Min amount";
export const MAXAMOUNT = "Max amount";
export const SKUID = "SKUID";
export const AMOUNT = "Amount";
export const FIX = "FIX";
export const RANGE = "RANGE";
export const ADD = "Add";
export const SEARCHGIFTVOUCHER = "Search Gift Voucher";
export const DASHBOARD = "Dashboard";
export const HOMEPAGE = "Home Page";
export const NAME = "Name";
export const EMAIL = "Email";
export const MOBILE = "Mobile No.";
export const STATUS = "Status";
export const EDIT = "Edit";
export const DELETE = "Delete";
export const ACTIVATEORDEACTIVATE = "Activate/Deactivate";
export const BRANDNAME = "Brand Name";
export const BILLERID = "BILLERID";
export const PRODUCTNAME = "Product Name";
export const VALIDITY = "Validity";
export const DISCOUNTPERCENTAGE = "Discount Percentage";
export const UPDATEGIFTVOUCHER = "Update Gift Voucher";
export const DOYOUWANTTOCHNAGETHEIMAGE = "Do you want to change the image?";
export const VIEWGROUPS = "View Groups";
export const GROUPS = "Groups";
export const ADDGROUP = "Add Group";
export const SEARCHGROUP = "Search Group";
export const GROUPNAMEERR = "Group name is required";
export const ENTERGROUPNAME = "Enter group name";
export const UPDATEGROUP = "Update Group";
export const CREATENOTIFICATIONS = "Create Notifications";
export const VIEWNOTIFICATIONS = "View Notifications";
export const NOTIFICATIONS = "Notifications";
export const TITLE = "Title";
export const CONTENT = "Content";
export const IMAGE = "Image";
export const ICON = "Icon";
export const ACTION_DETAILS = "Action details ";
export const ACTION_BUTTON_TYPE = "Action Button Type *";
export const ACTION_BUTTON_TARGET = "Action Button Target *";
export const URLCONSTANT = "url";
export const ACTION_PARAMETERS = "Action Parameters (Optional)";
export const THIS_FIELD_IS_REQUIRED = "This field is required";
export const FIELD_MUST_CONTAIN_MORE_THAN_EIGHT_CHAR =
    "This field must contain more than 8 Characters";
export const ACTION_BUTTON_LABEL = "Action Button Label";
export const NOT_VALID_JSON = "Not a valid json";
export const IMAGE_IS_REQUIRED = "Image is required";
export const CREATE_NOTIFICATIONS = "Create Notifications";
export const SEND_NOTIFICATIONS = "Send Notifications";
export const VIEWUSERSG = "View Users";
export const ADDUSERS = "Add Users to";
export const ADDNEWUSERS = "Add New Users";
export const ADDTOGROUP = "Add To Group";
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const CREATEGROUP = "Create Group";
export const VIEWGV = "View Gift Vouchers";
export const SELECT_OPTION_TO_SEND =
    "Select one of the options to send notifications";
export const SELECT_GROUP = "Select Group/Groups";
export const SELECT_USERS = "Select User/Users";
export const PRIVACYANDPOLICY = "Privacy And Policy";
export const TITLE_FAQ = "FAQs";
export const VIEWFAQ = "View FAQs";
export const ADDFAQ = "Add FAQ";
export const SEARCHFAQ = "Search FAQ";
export const TITLEERR = "Title is required";
export const CONTENTERR = "Content is required";
export const ENTERTITLENAME = "Enter title";
export const ENTERCONTENT = "Enter content *";
export const UPDATEFAQ = "Update FAQ";
export const VIEWBECOMEMERCHANT = "View Become Merchant Requests";
export const SEARCHBECOMEMERCHANT = "Search Become Merchant";
export const MERCHANTNAME = "Merchant Name";
export const CREATEDDATE = "Created Date";
export const SUPPORTREQUEST = "Support Requests";
export const VIEWSUPPORTREQUEST = "View Support Requests";
export const SEARCHSUPPORTREQUEST = "Search Support Request";
export const SELECT = "Select";
export const SELECTED = "Selected";
export const START_DATE = "From Date";
export const END_DATE = "To Date";
export const APPLY = "APPLY";
export const DATEPICKERLABEL = "Date Range";
export const BRANCH = "Branch";
export const COMPLAINT = "Complaint";
export const CATEGORY_NAME = "Category Name";
export const ACCOUNT_NUMBER = "Account Number";
export const SUCCESS = "Success";
export const FAILED = "Failed";
export const PENDING = "Pending";
export const ACCOUNT_STATEMENT = "Transaction Statement";
export const DOWNLOAD_STATEMENT = "Download Statement";
export const TRANSACTIONID = "Transaction ID";
export const TRANSACTION_DATE = "Transaction Date";
export const TRANSACTION_STMT = "Transaction Statements";
export const SETTINGS = "Settings";
export const PARTICULARS = "Particulars";
export const MODE = "Mode";
export const CLOSING_BALANCE = "Closing Balance (INR)";
export const LAST_NAME = "Last Name";
export const FIRST_NAME = "First Name";
export const DELETE_RESTRICTED = "Delete Restricted";
export const COUNT = "Count";
export const GROUP_NAME = "Group Name";
export const CREDIT = "Credit (INR)";
export const DEBIT = "Debit (INR)";
export const TOTAL_CREDITS = "Total credits";
export const TOTAL_DEBITS = "Total debits";
export const IMAGE_ERR = "Image is required";
export const ENTERDESCRIPTION = "Enter description *";
export const ENTERTNC = "Enter terms and Condition *";
export const DASHBOARDONWEB = "Display on dashboard web";
export const DASHBOARDONMOB = "Display on dashboard mobile";
export const VIEW_SUPPORT_CATEGORIES = "View Support Categories";
export const SUPPORTREQUESTCATEGORIES = "View Support Request Categories";
export const CATEGORYNAMEERR = "Category name is required";
export const ADDCATEGORYNAME = "Add Category Name";
export const ENTERCATEGORYNAME = "Enter category name";
export const UPDATECATEGORY = "Update Category";
export const CHARGES = "Charges";
export const VIEWCHARGES = "View Charges";
export const SEARCHSERVICE = "Search Service";
export const ADDCHARGE = "Add Charge";
export const ENTERMINAMOUNT = "Enter minimum amount";
export const ENTERMAXAMOUNT = "Enter maximum amount";
export const ENTERCHARGES = "Enter charges";
export const NEFT = "NEFT";
export const IMPS = "IMPS";
export const SERVICETYPE = "Select service type *";
export const UPDATECHARGE = "Update Charge";
export const SERVICEERR = "Service is required";
export const CHARGESERR = "Charges are required";
export const MAXAMOUNTSHOULDBEGREATERTHANMINAMOUNT =
    "Max amount should be greater than Min amount";
export const FIXED = "FIXED";
export const PERCENTAGE = "PERCENTAGE";
export const PLEASESELECTATRANSFERTYPE = "Please select a transfer type.";
export const SERVICE = "Service";
export const CHARGETYPE = "Charge Type";
export const CHARGE = "Charge";
export const WALLET_STMT = "Wallet Statements";
export const SELECT_WALLET = "Select Wallet";
export const TOTAL = "Total";
export const AGENTTRANSACTIONID = "Agent Transaction Id";
export const SPTRANSACTIONID = "SP Transaction Id";
export const TRANSACTIONDATEANDTIME = "Transaction Date & Time";
export const THEGIFTVOUCHER = "the Gift voucher?";
export const VIEWSTATEMENT = "View statements";
export const VIEWCUSTUSERS = "View Customers";
export const SEARCHCUSTOMER = "Search Customer";
export const CUSTOMER_NAME = "Customer Name";
export const CUSTOMER_ID = "Customer ID";
export const BRANCH_ID = "Branch ID";
export const BRANCH_NAME = "Branch Name";
export const DOWNLOADADMINUSERS = "Download Admin List";
export const DOWNLOADSTAFFUSERS = "Download Staff List";
export const DOWNLOADCUSTOMERS = "Download Customer List";
export const DOYOUWANTTORESETTHEPASSWORD =
    "This will reset password for this user’s account. Do you want to continue?";
export const UPDATENOTIFICATIONS = "Update Notifications";
export const DOYOUWANTTOCHNAGETHEICON = "Do you want to change the icon?";
export const INVALIDIMGURLFORMAT = "Invalid image URL format";
export const INVALIDIMGFORMAT = "Invalid image format";
export const SEARCHNOTIFICATION = "Search Notification";
export const ALL = "All";
export const OTP_VERIFICATION = "OTP Verification";
export const OTP_SENT_TO_YOUR_REGISTERED_MOBILE_NUMBER =
    "OTP sent to your registered mobile number";
export const DIDNT_RECIEVE_YOUR_OTP = "Didn’t you receive OTP?";
export const RESEND = "Resend";
export const OTPISREQUIRED = "OTP is required";
export const DETAILS = "Details";
export const TYPE = "Type";
export const ACTION_TARGET = "Action Target";
export const ACTION_TYPE = "Action Type";
export const ACTION = "Action";
export const VIEWINSURANCE = "View Insurance Requests";
export const SEARCHVIEWINSURANCE = "Search (name,email,mobile)";
export const VIEW_DETAILS = "View Details";
export const TYPEOFINSURANCE = "Type of Insurance";
export const DATEOFBIRTH = "Date of Birth";
export const CITY = "City";
export const NUMBEROFDEPENDENTS = "Number of dependents";
export const OCCUPATION = "Occupation";
export const PINCODE = "Pincode";
export const REQUIREMENT = "Requirement";
export const DOYOUWANTTOCONVERTTOMERCHANT =
    "This will convert into Merchant. Do you want to continue?";
export const VIEWMERCHANTTUSERS = "View Merchant Users";
export const DOWNLOADMERCHANTLIST = "Download Merchant List";
export const SEARCHMERCHANT = "Search Merchant User";
export const DOYOUWANTTOCONVERTTOCUSTOMER =
    "This will remove merchant access for this user and user will be treated as normal customer. Do you want to continue?";
export const CONVERTTOCUSTOMER = "Remove merchant access";
export const CONVERTTOMERCHANT = "Convert to Merchant";
export const VIEWFAQCATEGORY = "View FAQ Categories";
export const SELECTCATEGORY = "Select category *";
export const DOYOUWANTTODELETETHISFAQ = "Do you want to delete this FAQ?";
export const DOYOUWANTTODELETETHISFAQCATEGORY =
    "Do you want to delete this FAQ Category?";
export const PLEASESELECTACATEGORY = "Please select a category";
export const BECOMEMERCHANTREQUESTS = "Become Merchant Requests";
export const COMMISSIONS = "Commissions";
export const VIEWCOMMISSIONMERCHANTSET = "Merchant Commissions";
export const VIEWMERCHANTCOMMISIONREPORT = "Merchant Commission Report";
export const ADDCOMMISSIONS = "Add Commission Template";
export const SEARCHCOMMISSIONS = "Search Commissions";
export const SERVICENAME = "Service Name";
export const COMMISSION = "Commission";
export const COMMISSIONTYPE = "Commission Type";
export const TEMPLATENAMEERR = "Template name is required";
export const ADDTEMPLATENAME = "Add Template Name";
export const ADDTEMPLATE = "Add template";
export const SELECTTEMPLATE = "Select template *";
export const ENTERTEMPLATENAME = "Enter template name";
export const PLEASESELECTATEMPLATE = "Please select a template";
export const SELECTSERVICE = "Select service *";
export const PLEASESELECTASERVICE = "Please select a service";
export const SELECTCOMMISSIONTYPE = "Select commission type *";
export const COMMISSIONTYPERR = "Please select a commission type";
export const COMMISSIONERR = "Please enter a commission";
export const SEARCHBYTEMPLATENAME = "Search by Template Name *";
export const VIEWSHOWINTEREST = "View Notification Interests";
export const SEARCHINTEREST = "Search";
export const LABEL_ACTION_BUTTON_TYPE = "Action Button Type";
export const ACCEPTORREJECT = "Accept / Reject";
export const ACCEPT = "Accept";
export const REJECT = "Reject";
export const DOYOUWANTTOACCEPTTHISMERCHANTREQUEST =
    "Do you want to accept this merchant request?";
export const DOYOUWANTTOREJECTTHISMERCHANTREQUEST =
    "Do you want to reject this merchant request?";
export const PLEASEENTERREMARK = "Please Enter Remark";
export const SHOWALL = "Show All";
export const VIEWCOMMISSIONMASTER = "Commission Master";
export const VIEWCOMMISSIONTEMPLATE = "Commission Template";
export const SEARCHCOMMISSIONTEMPLATE = "Search Commission Template";
export const ASSIGNCOMMISSIONS = "Assign Commission Template";
export const UPDATETEMPLATE = "Update Template";
export const SELECTMERCHANTUSER = "Select merchant user *";
export const DOYOUWANTTODELETETHISCOMMISSION =
    "Do you want to delete this commission?";
export const SAVE = "Save";
export const PENDING_STMT = "Pending Transactions";
export const REFRESH = "Refresh";
export const SEARCHTRANSACTION = "Search Transaction";
export const PLEASEUPLOADIMG = "Please upload an image or provide a valid URL";
export const PIPEEERR = "Pipe is required";
export const PIPETYPE = "Select pipe *";
export const PIPE = "Pipe";
export const GENDER = "Gender";
export const SHOWINTERESTCOUNT = "Show Interest Count";
export const USERCOUNT = "User Count";
export const MORE = "More";
export const PLEASESELECTUSERTYPE = "Please select user type";
export const SELECTUSERTYPE = "Select user type *";
export const CREATE = "Create";
export const UPDATE = "Update";
export const ADMINUSER = "Admin User";
export const SEARCHCATEGORY = "Search Category";
export const VIEWACTIVATIONREQUEST = "View Activation Request";
export const SEARCHACTIVATIONREQUEST = "Search activation request";
export const ADDGVCATEGORYNAME = "Add Gift Voucher Category";
export const ENTERGVCATEGORYNAME = "Enter gift voucher category";
export const UPDATEGVCATEGORY = "Update Gift Voucher Category";
export const VIEWGVCATEGORIES = "View Gift Voucher Categories";
export const ADDGVCATEGORY = "Add Gift Voucher Category";
export const SEARCHGVCATEGORY = "Search Gift Voucher Category";
export const CATEGORYTYPE = "Select category type *";
export const VIEWCUSTOMERSUPPORTDETAILS = "View Customer Support Info";
export const UPDATECUSTOMERSUPPORTINFO = "Update customer support info";
export const VIEWBANNERS = "View Banners";
export const URL = "Url";
export const ENTERURL = "Enter URL";
export const DOYOUWANTTODELETETHISBANNER =
    "Do you really want to delete this banner?";
export const ADDBANNER = "Add banner";
export const UPDATEBANNER = "Update banner";
export const THEBANNER = "the banner?";
export const BANNERS = "Banners";
export const PLEASESELECTBRANCH = "Please select branch";
export const SELECT_BRANCH = "Select Branch *";
export const VIEWTRANSACTION = "View Transaction";
export const REVERSETRANSACTIONFOR = "Reverse Transaction for";
export const VIEWTRANSACTIONDETAILS = "View Transaction Details";
export const REVERSETRANSACTION = "Reverse Transaction";
export const TRANSACTIONNOTFOUND = "Transaction not found";
export const USERNAMEREQ = "Username is required";
export const PASSWORDREQ = "Password is required";
export const ADD_REMARK = "Add Remark";
export const REMARK = "Remark";
export const TEMPLATE_NAME = "Template Name";
export const ACTIVE_USERS = "Active Users";
export const INACTIVE_USERS = "Inactive Users";
export const BBPS_CHART = "BBPS Transactions Chart : ";
export const ECOLLECT_CHART = "E-Collect Transactions Chart : ";
export const GIFT_VOUCHER_CHART = "Gift Voucher Transactions Chart : ";
export const RECHARGES_CHART = "Recharges Transaction Chart : ";
export const USER_STATUS = "User Status : ";
export const ACTIVE_AUTOPAY_COUNT = "Autopay Count";
export const ACTIVE_AUTOPAY_CHART = "Active Auto Pay Chart : ";
export const VIEW_NEFT_LIMIT = "View NEFT Limit";
export const ENTER_PER_TRANSACTION_LIMIT = "Enter Per Transaction Limit";
export const DAILY_LIMIT = "Daily Limit";
export const SELECT_LIMIT_TYPE = "Select Limit Type";
export const SELECT_USER = "Select User";
export const ADD_NEFT_LIMIT = "Add NEFT Limit";
export const DAILY_LIMIT_ERROR =
    "Daily Limit should be greater than Per Transaction Limit";
export const ADDUSERLIMIT = "Add User Limit";
export const DEFAULTDAILYLIMIT = "Default Daily Limit";
export const DAFAULTPERTRANSACTIONLIMIT = "Default Per Transaction Limit";
export const UPDATENEFTLIMIT = "Update NEFT Limit";
export const PLEASESELECTALIMITTYPE = "Please Select a limit type";
export const PLEASEENTERDAILYLIMIT = "Please enter daily limit";
export const SHAREBYUSERID = "Search by Share ID or User ID";
export const ADMINLOGS = "View Admin Logs";
export const SHAREID = "Share ID";
export const DATEANDTIME = "Date & Time";
export const VIEWBRANCHMONITOR = "View Branch Monitor";
export const ONLINE = "Online";
export const OFFLINE = "Offline";
export const NORESULTSFOUND = "No result found";
export const ADD_PIGMY_AGENT = "Add Pigmy Agent";
export const EDIT_PIGMY_AGENT = "Edit Pigmy Agent";
export const SEARCHPIGMYUSER = "Search Pigmy User";
export const EXISTING_CUSTOMER = "Existing agent";
export const NEW_CUSTOMER = "New agent";
export const SELECT_PIGMY_AGENT = "Select Pigmy Agent";
export const DAILY_COLLECTION_AMOUNT = "Daily Collection Amount";
export const VIEW_PIGMY_CUSTOMERS = "View Pigmy Customers";
export const SEARCH_PIGMY_AGENTS = "Search Pigmy Agent";
export const SEARCHPIGMYCUSTOMERS = "Search Pigmy Customer";
export const ASSIGN_CUSTOMERS = "Assign Customers";
export const REMOVE_REASSIGN_ASSIGNMENT = "Reassign/ Remove Assignment";
export const REASSIGN = "Reassign";
export const DOYOUWANTTODELETETHISCUSTOMER =
    "Do you want to delete this customer from selected Agent?";
export const REASSIGN_PIGMY_AGENT = "Reassign Pigmy Agent To";
export const MAINTENANCE = "Maintenance";
export const MOBILE_BUILD = "View Mobile Builds";
export const BUILD_VERSION = "Build Version";
export const DEVICE_TYPE = "Device Type";
export const FORCE_UPDATE_FLAG = "Force Update Flag";

export const ADD_BUILD_VERSION = "Add Build Version";
export const SEARCH_BUILD_VERSIONS = "Search Build Versions";
export const IOS = "iOS";
export const ANDROID = "Android";
export const SELECT_DEVICE_TYPE = "Select Device Type";
export const BUILDVERSIONISREQUIRED = "Build Version Is Required";
export const FORCEUPDATESTATUSREQUIRED = "Force Update Flag Is Required";
export const BUILDURLSREQUIRED = "Build URL Is Required";
export const DEVICETYPEREQUIRED = "Device Type Is Required";
export const DOYOUWANTTODELETETHISBUILD =
    "Do you really want to delete this build?";
export const EDIT_BUILD_VERSION = "Edit Build Version";
export const INVALIDBUILDVERSION = "Invalid Build Version Format";

export const INVALIDBUILDURL = "Invalid URL Format";
export const AGENT_NAME = "Agent Name";
export const VIEW = "View";
export const VIEW_PIGMY_TRANSACTIONS = "View Pigmy Transactions";
export const AGENT = "Agent";
export const CUSTOMER = "Customer";
export const ACCOUNT = "Account";
export const SELECT_PIGMY_CUSTOMER = "Select Pigmy Customer";
export const SELECT_PIGMY_ACCOUNT = "Select Pigmy Account";
export const ADD_CUSTOMER = "Add Customer";
export const PIGMY_STATEMENT = "Pigmy Transaction Statement";
export const IS_PIGMY_CUSTOMER = "Is Pigmy Customer";
export const PIGMY = "Pigmy";
export const VIEWPIGMYAGENTS = "View Pigmy Agents";
export const RECHARGES_LIMIT_CONFIG = "Recharge Limit Configurations";
export const VIEWPIGMYCOLLECTION = "View Pigmy Collection";
export const TOTAL_COLLECTION = "Total collection";
export const VIEW_COLLECTION = "View Collections";
export const DEPOSITDATE = "Deposit Date";
export const DEPOSITAMOUNT = "Total Deposit Amount";
export const CONFIRMATIONDEPOSITMSG =
    "Do you want to confirm this amount to be deposit?";

export const VIEW_CUSTOMERS = "View Customers";
export const VIEW_PIGMY_AGENTS = "View Pigmy Agents";
