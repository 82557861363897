import { createSlice } from "@reduxjs/toolkit";

const eventListInitState = {
  eventListData: [],
  eventListError: false,
  isLoading: false,
};

const getLocationsInitState = {
  getLocationsData: [],
  getLocationsError: false,
  isLocationLoading: false,

};

const getCameraListInitState = {
  getCameraListData: [],
  getCameraListError: false,
  isCameraListLoading: false,

};

const eventListSlice = createSlice({
  name: "eventList",
  initialState: eventListInitState,
  reducers: {
    eventListSuccess: (state, action) => {
      state.eventListData = action.payload;
      state.eventListError = false;
    },
    eventListFailed: (state, action) => {
      state.eventListError = action.payload;
      state.eventListData = [];
    },
    eventListLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});


const getLocationsSlice = createSlice({
  name: "getLocations",
  initialState: getLocationsInitState,
  reducers: {
    getLocationsSuccess: (state, action) => {
      state.getLocationsData = action.payload;
      state.getLocationsError = false;
    },
    getLocationsError: (state, action) => {
      state.getLocationsError = action.payload;
      state.getLocationsData = [];
    },
    getLocationsLoading: (state, action) => {
      state.isLocationLoading = action.payload;
    },
  },
});


const getCameraListSlice = createSlice({
  name: "getCameraList",
  initialState: getCameraListInitState,
  reducers: {
    getCameraListSuccess: (state, action) => {
      state.getCameraListData = action.payload;
      state.getCameraListError = false;
    },
    getCameraListError: (state, action) => {
      state.getCameraListError = action.payload;
      state.getCameraListData = [];
    },
    getCameraListLoading: (state, action) => {
      state.isCameraListLoading = action.payload;
    },
  },
});





export const { eventListSuccess, eventListFailed, eventListLoading } = eventListSlice.actions;
export const { getLocationsSuccess, getLocationsError, getLocationsLoading } = getLocationsSlice.actions
export const { getCameraListSuccess, getCameraListError, getCameraListLoading } = getCameraListSlice.actions


export { eventListSlice, getLocationsSlice, getCameraListSlice };
