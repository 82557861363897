import axios from "axios";
import { loginError, loginLoading, loginSuccess } from "../Slices/authSlice";

const LoginService = (payload, navigate) => async (dispatch) => {
  dispatch(loginLoading(true))

  await axios
    .post("http://103.83.39.56/login", payload)
    .then((response) => {
      console.log(response.data);
      dispatch(loginSuccess(response.data));
      navigate('/home')
      dispatch(loginLoading(false))
    })
    .catch((err) => {
      dispatch(loginLoading(false))
      dispatch(loginError(err.response));
    });
};

export { LoginService };
