import React, { useState } from "react";
import { Popover, InputAdornment, IconButton, Button } from "@material-ui/core";
import { AiOutlineCalendar } from "react-icons/ai";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Input from "../../components/Input/index";
import { styles } from "./dateRangePickerStyles";
import { START_DATE, END_DATE, APPLY, DATEPICKERLABEL } from "../../constants";

const dateFormat = "DD MMM YYYY";

const DateRangePicker = ({
  classes,
  containerStyled,
  width,
  inputClass,
  onChange,
  startDate,
  fullWidth,
  endDate,
  style,
  applyCloseHandler,
}) => {
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);
  const [inputValue, setInputValue] = useState(
    `${moment(fromDate).format(dateFormat)} - ${moment(toDate).format(
      dateFormat
    )}`
  );

  const onAdornmentClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const onInputChange = (e) => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);

    setInputValue(inputValue);
    setFromDate(fromDate);
    setToDate(toDate);

    onChange({ fromDate, toDate, inputValue });
  };

  const onPopoverClose = (e, reason) => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const applyClose = () => {
    setDisplayCalendar(false);
    applyCloseHandler();
  };

  const onSelectDateRanges = (rangesByKey) => {
    const startDate = moment(rangesByKey?.selection?.startDate).toDate();
    const endDate = moment(rangesByKey?.selection?.endDate).toDate();
    const inputValue = [
      startDate && moment(startDate).format(dateFormat),
      endDate && moment(endDate).format(dateFormat),
    ]
      .filter(Boolean)
      .join(" - ");

    setInputValue(inputValue);
    setFromDate(startDate);
    setToDate(endDate);

    onChange({ fromDate: startDate, toDate: endDate, inputValue });
  };

  const processInputValue = (value) => {
    const dateFormat = "YYYY-MM-DD";
    const [fromDateStr, toDateStr] = value.split("-").map((elm) => elm.trim());

    const fromDate = moment(fromDateStr, dateFormat);
    const parsedFromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    const toDate = moment(toDateStr, dateFormat);
    const parsedToDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate: parsedFromDate, toDate: parsedToDate };

  };

  const dateString = "01-01-2024"; // Date in 'DD-MM-YYYY' format
  const [day, month, year] = dateString.split('-'); // Split the string into day, month, and year

  const formattedDate = new Date(`${year}-${month}-${day}`);


  return (
    <div className={containerStyled}>
      <Input
        label={DATEPICKERLABEL}
        value={inputValue}
        width={width}
        className={inputClass}
        fullWidth={fullWidth}
        style={{ ...style, borderRadius: "8px !important" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <AiOutlineCalendar />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={onInputChange}
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <div className={classes.calendarWrapper}>
          <DateRange
            ranges={[
              {
                startDate: fromDate,
                endDate: fromDate,
                key: "selection",
              },
            ]}
            startDatePlaceholder={START_DATE}
            endDatePlaceholder={END_DATE}
            onChange={onSelectDateRanges}
            maxDate={new Date()}
            minDate={formattedDate}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={false}
            showDateDisplay={true}
            scroll={{ enabled: true }}
          />

          <Button
            variant="text"
            style={{
              backgroundColor: "#004B23",
              color: "white",
              width: 115,
              borderRadius: 12,
              height: 50,
              marginTop: 20,
            }}
            onClick={applyClose}
          >
            {APPLY}
          </Button>
        </div>
      </Popover>
    </div>
  );
};

//@ts-ignore
export default withStyles(styles, { name: "DateRangePicker" })(DateRangePicker);
