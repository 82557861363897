import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Separator from "../../components/Separator";
import Datatable from "../../components/Datatable/PaginatedTable";
import { SEARCH } from "../../constants";
import { SearchInput } from "../../components/SearchInput";
import { Dropdown } from "../../components/Dropdown/index";
import { MenuItem } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Box } from '@mui/material';
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import moment from "moment";
import theme from "../../theme";
import { container, muiBreakpoints } from "../../utils/commonstyles";
import DownloadBtn from '../../assets/downloadBtn.svg'
import { useDispatch, useSelector } from "react-redux";
import { getCameraList, getEventList, getLocationList } from "../../redux/Services/eventService";
import { cameraListState, eventListState, locationsState, servicesState } from "../../redux/Selectors";
import { getServices } from "../../redux/Services/facilitiesService";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loader from '../../components/Loader'
import dayjs from "dayjs";
import Sidebar from "../../components/Sidebar";
import { useLocation } from "react-router-dom";
import { Label } from "../../components/Label";

const Styles = makeStyles(() => ({
  searchStyle: {
    width: "300px",
    height: "45px !important",
  },
  inputClass: {
    width: "282px !important",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-root fieldset": {
      borderRadius: "8px !important",
    },
  },
  datecontainerStyled: {
    [theme.breakpoints.down(muiBreakpoints.laptop)]: {
      marginLeft: 0,
      marginTop: 12,
      marginBottom: 12,
    },
  },
}));

export default function ServiceDetails() {
  const styles = Styles();
  const location = useLocation()
  const dispatch = useDispatch();
  const { getServicesData, isServicesLoading } = useSelector(servicesState)
  const getDateWithTZ = (dateObj) => {
    return moment(dateObj).format("YYYY-MM-DDTHH:mm:ssZ");
  };
  const today = new Date();

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState(getDateWithTZ(moment()));
  const [searchTerm, setSearchTerm] = useState("");
  const changedFromDate = useRef(fromDate);
  const changedToDate = useRef(toDate);
  const { eventListData, isLoading } = useSelector(eventListState);
  const [selectedLocation, setSelectedLocation] = useState('')
  const { getCameraListData, isCameraListLoading } = useSelector(cameraListState);
  const { getLocationsData, isLocationLoading } = useSelector(locationsState)
  const [selectedCamera, setSelectedCamera] = useState('')
  const data = location?.state?.data


  const payload = {
    strSearch: "",
    funName: data?.FunctionName,
    camName: "",
    camLoc: "",
    dt: "",
    dtTimeHour: "",
    dtTimeMin: ""
  }

  useEffect(() => {
    dispatch(getEventList(payload))
    dispatch(getCameraList())
    dispatch(getLocationList())
  }, [])

  useEffect(() => {
    const payload = {
      strSearch: "",
      funName: data?.FunctionName,
      camName: selectedCamera,
      camLoc: selectedLocation,
      dt: fromDate == "" ? "" : moment(fromDate?.$d).format("YYYYMMDD"),
      dtTimeHour: "",
      dtTimeMin: ""
    }
    dispatch(getEventList(payload))
  }, [selectedCamera, selectedLocation, fromDate, data?.FunctionName])

  const handleSearchInputChange = (str) => {
    setSearchTerm(str)
  };

  const columns = [
    {
      field: "Date",
      headerName: "Date",
      minWidth: 280,
      sortable: false,
      renderCell: (params) => (
        <span>{params.value !== "" ? moment(params.value).format('DD-MM-YYYY') : ""}</span>
      ),
    },
    {
      field: "CamTimestamp",
      headerName: "Time",
      minWidth: 280,
      sortable: false,
      renderCell: (params) => (
        <span>{params.value !== "" ? moment(params.value).format('HH:mm A') : ""}</span>
      ),
    },
    {
      field: "WorkLocation",
      headerName: "Work Location",
      minWidth: 280,
      sortable: false,
    },
    {
      field: "CamDesc",
      headerName: "Camera Name",
      minWidth: 180,
      sortable: false,
    },
    {
      field: "EmployeeName",
      headerName: "Employee Name",
      minWidth: 280,
      sortable: false,
      renderCell: (params) => (
        <span style={{ backgroundColor: "#C9E8E8", padding: "4px 12px 4px 12px" }}>{params.value !== "" ? params.value : ""}</span>
      ),
    },
  ]

  function hexToBase64(hexString) {
    const byteArray = new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
    const base64String = btoa(String.fromCharCode(...byteArray));
    return base64String;
  }

  return (
    <div style={container}>
      <Sidebar section={location?.state?.section || 2} />
      <div
        style={{
          backgroundColor: "white",
          height: "95vh",
          width: "85%",
          borderRadius: 12,
          margin: "12px 0px 12px 0px",
          alignSelf: "center",
          overflowY: "auto"
        }}
      >
        <Box
          sx={{
            height: 160,
            bgcolor: data?.Color,
            borderRadius: '10px',
            padding: '12px',
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-between",
            margin: "12px"
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={`data:image/png;base64,${hexToBase64(data?.IconImage)}`}
              style={{ height: '120px', width: '120px' }} />
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Label
                fontSize={20}
                fontWeight={"500"}
                color={"#FFFFFF"}
                title={'Total Alerts'}
                style={{ marginLeft: 24 }}
              />
              <Label
                fontSize={44}
                fontWeight={"500"}
                color={"#FFFFFF"}
                title={data?.FunctionEvemtCount}
                style={{ marginLeft: 24 }}
              />
            </Box>
          </Box>

          <Label
            fontSize={44}
            fontWeight={"500"}
            color={"#FFFFFF"}
            title={data?.FunctionName}
            style={{ marginLeft: 24, opacity: '0.2' }}
          />
        </Box>

        <div style={{
          display: 'flex', flexDirection: 'row', margin: "12px",
          justifyContent: "space-between", gap: '16px', paddingBottom: '12px', alignItems: 'center'
        }}>
          <div style={{ marginTop: "5px", }}>
            <SearchInput
              height={42}
              searchTerm={searchTerm}
              title={SEARCH}
              onSubmit={handleSearchInputChange}
            />
          </div>
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <div style={{ marginTop: "10px" }}>
              <Dropdown
                label={"Location"}
                value={selectedLocation}
                onChange={(event) => {
                  setSelectedLocation(event.target.value)
                }}
                rootFormClass={styles.searchStyle}
              >
                {getLocationsData.map((option, index) => (
                  <MenuItem key={`service_${option}_${index}`} value={option?.name}>
                    {option?.name}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
            <div style={{ marginTop: "10px" }}>

              <Dropdown
                label={"Camera Name"}
                value={selectedCamera}
                onChange={(event) => {
                  setSelectedCamera(event.target.value);
                }}
                rootFormClass={styles.searchStyle}
              >
                {getCameraListData.map((option, index) => (
                  <MenuItem key={`camera_${option}_${index}`} value={option?.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  overflow: 'hidden', // Prevent overflow causing scroll
                }}
              >
                <DemoContainer components={['DateTimePicker']} >
                  <DateTimePicker
                    label="Basic date time picker"
                    onChange={(newValue) => setFromDate(newValue)}
                    value={fromDate || dayjs(new Date())}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '40px', // Adjust the input field height
                        overflow: 'hidden', // Ensure no scrollbars appear
                        // width: '220px',
                        boxSizing: 'border-box', // Ensure padding and border are included in the width
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '40px', // Adjust the entire input area height
                        overflow: 'hidden', // Hide any potential overflow
                        // width: '220px',
                        boxSizing: 'border-box', // Ensure padding and border are included in the width
                      },
                      '& .MuiPopover-paper': {
                        overflow: 'hidden', // Ensure the popover (calendar) doesn't cause scrollbars
                      },
                      '& .MuiFormControl-root': {
                        // minWidth: '220px', // Set the minimum width to avoid overflow issues
                        boxSizing: 'border-box', // Ensure padding and border are included in the width
                      }
                    }}
                  />
                </DemoContainer>

              </Box>
            </LocalizationProvider>

            <img src={DownloadBtn} height={'30px'} width={'30px'} style={{ marginTop: '10px' }} alt="download_btn" />
          </div>
        </div>
        <Box sx={{
          margin: "12px"

        }}>
          <Datatable
            key={`eventsData`}
            type="paginated"
            rows={eventListData}
            columns={columns}
            getRowId={function (row) {
              return row.id;
            }}
            rowCount={eventListData?.length}
            paginationMode="client"
          />
        </Box>

      </div>
    </div >
  )
}
