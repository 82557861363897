import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Separator from '../../components/Separator';
import { Label } from '../../components/Label';
import { useDispatch, useSelector } from 'react-redux';
import { getServices } from '../../redux/Services/facilitiesService';
import { servicesState } from '../../redux/Selectors';
import { container } from '../../utils/commonstyles';
import Sidebar from '../../components/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';

function Services(props) {

  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { getServicesData } = useSelector(servicesState)

  useEffect(() => {
    dispatch(getServices())
  }, [])

  function hexToBase64(hexString) {
    const byteArray = new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
    const base64String = btoa(String.fromCharCode(...byteArray));
    return base64String;
  }

  return (
    <div style={container}>
      <Sidebar section={location.state.section} />
      <div
        style={{
          backgroundColor: "white",
          height: '95vh',
          margin: '12px 12px 12px 0px',
          minWidth: "83vw",
          borderRadius: 12,
          alignSelf: "center",
          overflowY: "auto"
        }}
      >
        <Header title={'Services'} />
        <Separator />
        <div style={{ margin: '32px' }}>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            {getServicesData?.map((item) => {
              return (
                <div
                  onClick={() => { navigate('/services/service-details', { state: { data: item } }) }}
                  style={{
                    height: '62px',
                    borderRadius: 10,
                    width: "30%",
                    backgroundColor: item?.Color,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 36
                  }}>
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: 18,
                    paddingRight: 18,
                    alignItems: "center",
                  }}>
                    <img src={`data:image/png;base64,${hexToBase64(item?.IconImage)}`} style={{ height: '35px', width: '35px' }} />
                    <Label
                      fontSize={20}
                      fontWeight={"600"}
                      color={"#FFFFFF"}
                      title={item?.FunctionName}
                      style={{ marginLeft: 12 }}
                    />
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                      <Label
                        fontSize={10}
                        fontWeight={"500"}
                        color={"#FFFFFF"}
                        title={'Total Alerts'}
                      />
                      <Label
                        fontSize={20}
                        fontWeight={"600"}
                        color={"#FFFFFF"}
                        title={item?.FunctionEvemtCount}
                      />
                    </div>

                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;