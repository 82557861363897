import { combineReducers } from "@reduxjs/toolkit";
import { loginSlice } from "../Slices/authSlice";
import { eventListSlice, getCameraListSlice, getLocationsSlice } from "../Slices/eventSlice";
import { getServicesSlice } from "../Slices/servicesSlice";

const rootReducer = combineReducers({
  login: loginSlice.reducer, // Add your slices or reducers here
  events: eventListSlice.reducer,
  services: getServicesSlice.reducer,
  locations: getLocationsSlice.reducer,
  Camera: getCameraListSlice.reducer




});

export default rootReducer;
