import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Separator from "../../components/Separator";
import Datatable from "../../components/Datatable/PaginatedTable";
import { SEARCH } from "../../constants";
import { SearchInput } from "../../components/SearchInput";
import { Dropdown } from "../../components/Dropdown/index";
import { MenuItem } from "@mui/material";
import { Box, makeStyles } from "@material-ui/core";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import moment from "moment";
import theme from "../../theme";
import { container, muiBreakpoints } from "../../utils/commonstyles";
import DownloadBtn from '../../assets/downloadBtn.svg'
import { useDispatch, useSelector } from "react-redux";
import { getCameraList, getEventList } from "../../redux/Services/eventService";
import { cameraListState, eventListState, servicesState } from "../../redux/Selectors";
import { getServices } from "../../redux/Services/facilitiesService";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loader from '../../components/Loader'
import dayjs from "dayjs";
import Sidebar from "../../components/Sidebar";
import { useLocation } from "react-router-dom";

const list = [
  {
    field: "EventName",
    headerName: "Event Name",
    minWidth: 280,
    sortable: false,
    renderCell: (params) => (
      <div style={{ display: "flex", gap: 18 }}>
        <img src={`http://103.83.39.56/api/get_event_log_image/${params.id}`} height={50} width={80} alt="Event Log Image" />

        {/* <img src={params?.img} height={50} width={80} /> */}
        <span>{params.value !== "" ? params.value : ""}</span>
      </div>
    ),
  },
  {
    field: "EmployeeName",
    headerName: "Employee Name",
    minWidth: 200,
    sortable: false,
    renderCell: (params) => (
      <span>{params.value !== "" ? params.value : ""}</span>
    ),
  },
  {
    field: "CamDesc",
    headerName: "Camera Name",
    minWidth: 180,
    sortable: false,
  },
  {
    field: "CamTimestamp",
    headerName: "Date & Time",
    minWidth: 280,
    sortable: false,
  },
  {
    field: "FunctionName",
    headerName: "Function Name",
    minWidth: 280,
    sortable: false,
  },
  {
    field: "WorkLocation",
    headerName: "Work Location",
    minWidth: 180,
    sortable: false,
  },
];


const searchOptions = [
  "Option 1",
  "Option 2",
  "Option 3",
  "Option 4",
  "Option 5",
];

const Styles = makeStyles(() => ({
  searchStyle: {
    width: "300px",
    height: "45px !important",
  },
  inputClass: {
    width: "282px !important",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-root fieldset": {
      borderRadius: "8px !important",
    },
  },
  datecontainerStyled: {
    [theme.breakpoints.down(muiBreakpoints.laptop)]: {
      marginLeft: 0,
      marginTop: 12,
      marginBottom: 12,
    },
  },
}));

const Events = () => {
  const styles = Styles();
  const location = useLocation()
  const dispatch = useDispatch();
  const { getServicesData, isServicesLoading } = useSelector(servicesState)
  const getDateWithTZ = (dateObj) => {
    return moment(dateObj).format("YYYY-MM-DDTHH:mm:ssZ");
  };
  const today = new Date();

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState(getDateWithTZ(moment()));
  const [searchTerm, setSearchTerm] = useState("");
  const changedFromDate = useRef(fromDate);
  const changedToDate = useRef(toDate);
  const { eventListData, isLoading } = useSelector(eventListState);
  const [selectedService, setSelectedService] = useState('')
  const { getCameraListData, isCameraListLoading } = useSelector(cameraListState);
  const [selectedCamera, setSelectedCamera] = useState('')

  const payload = {
    strSearch: "",
    funName: "",
    camName: "",
    camLoc: "",
    dt: "",
    dtTimeHour: "",
    dtTimeMin: ""
  }

  useEffect(() => {
    dispatch(getEventList(payload))
    dispatch(getCameraList())
  }, [])


  useEffect(() => {
    dispatch(getServices())
  }, [])


  useEffect(() => {
    const payload = {
      strSearch: searchTerm,
      funName: selectedService,
      camName: selectedCamera,
      camLoc: "",
      dt: fromDate === "" ? "" : moment(fromDate?.$d).format("YYYYMMDD"),
      dtTimeHour: "",
      dtTimeMin: ""
    }

    dispatch(getEventList(payload))


  }, [searchTerm, selectedCamera, selectedService, fromDate])


  const handleSearchInputChange = (str) => {
    setSearchTerm(str)
  };
  const handleSearchChange = (event) => { };

  const applyCloseHandler = () => { }

  return (
    <div style={container}>
      <Sidebar section={location.state.section} />
      <div
        style={{
          backgroundColor: "white",
          height: '95vh',
          margin: '12px 12px 12px 0px',
          minWidth: "83vw",
          borderRadius: 12,
          alignSelf: "center",
          overflowY: "auto"
        }}
      >

        <Header title={"Events"} />
        <Separator />
        {(isLoading || isServicesLoading || isCameraListLoading) && (
          <Loader />
        )}

        <div style={{ padding: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", gap: '16px', paddingBottom: '12px', alignItems: 'center' }}>
            <div style={{ marginTop: "5px", }}>
              <SearchInput
                height={42}
                searchTerm={searchTerm}
                title={SEARCH}
                onSubmit={handleSearchInputChange}
              />
            </div>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ marginTop: "10px" }}>
                <Dropdown
                  label={"Service Name"}
                  value={selectedService}
                  onChange={(event) => {
                    setSelectedService(event.target.value)
                  }}
                  rootFormClass={styles.searchStyle}
                >
                  {getServicesData.map((option, index) => (
                    <MenuItem key={`service_${option}_${index}`} value={option?.FunctionName}>
                      {option?.FunctionName}
                    </MenuItem>
                  ))}
                </Dropdown>
              </div>
              <div style={{ marginTop: "10px" }}>

                <Dropdown
                  label={"Camera Name"}
                  value={selectedCamera}
                  onChange={(event) => {
                    setSelectedCamera(event.target.value);
                  }}
                  rootFormClass={styles.searchStyle}
                >
                  {getCameraListData.map((option, index) => (
                    <MenuItem key={`camera_${option}_${index}`} value={option?.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Dropdown>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    overflow: 'hidden', // Prevent overflow causing scroll
                  }}
                >
                  <DemoContainer components={['DateTimePicker']} >
                    <DateTimePicker
                      label="Basic date time picker"
                      onChange={(newValue) => setFromDate(newValue)}
                      value={fromDate || dayjs(new Date())}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '40px', // Adjust the input field height
                          overflow: 'hidden', // Ensure no scrollbars appear
                          // width: '220px',
                          boxSizing: 'border-box', // Ensure padding and border are included in the width
                        },
                        '& .MuiOutlinedInput-root': {
                          height: '40px', // Adjust the entire input area height
                          overflow: 'hidden', // Hide any potential overflow
                          // width: '220px',
                          boxSizing: 'border-box', // Ensure padding and border are included in the width
                        },
                        '& .MuiPopover-paper': {
                          overflow: 'hidden', // Ensure the popover (calendar) doesn't cause scrollbars
                        },
                        '& .MuiFormControl-root': {
                          // minWidth: '220px', // Set the minimum width to avoid overflow issues
                          boxSizing: 'border-box', // Ensure padding and border are included in the width
                        }
                      }}
                    />
                  </DemoContainer>

                </Box>
              </LocalizationProvider>

              <img src={DownloadBtn} height={'30px'} width={'30px'} style={{ marginTop: '10px' }} alt="download_btn" />
            </div>
          </div>
          <Datatable
            key={`eventsData${eventListData}`}
            type="paginated"
            rows={eventListData}
            columns={list}
            getRowId={function (row) {
              return row.id;
            }}
            rowCount={eventListData?.length}
            paginationMode="client"
          />
        </div>
      </div>
    </div >
  );
};

export default Events;
