import React, { useEffect, useRef, useState } from "react";
import {
  PaginatedGrid,
  RoundedPaginationContainer,
  ShowrecordsContainer,
  StyledMuiPagination,
  StyledPageSelector,
  useAccountStyles,
} from "./dataTableStyles";
import { PaginationItem } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Label } from "../Label";
import {
  OF,
  RECORDS,
  RECORDS_PER_PAGE,
  SHOW,
  SHOWING,
  TO,
} from "../../constants";
import theme from "../../theme/index";

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const PaginatedTable = (props) => {
  const {
    columns,
    rows,
    getRowId,
    CustomizedToolBar,
    checkboxSelection,
    onRowSelectionModelChange,
    rowCount,
    setPaginationModel,
    paginationMode,
    isSearching,
    onRowEditStop,
    onRowModesModelChange,
    processRowUpdate,
    rowModesModel,
    slotProps,
    editMode,
    keepNonExistentRowsSelected,
  } = props;
  const [page, setPage] = useState(0);
  const isMount = useIsMount();
  const [pageSize, setPageSize] = useState(10);
  const classes = useAccountStyles();
  const availablePageSizes = [5, 10, 15, 20, 50, 100];
  const isRowCount = rowCount && rowCount !== 0;
  const firstRecord = pageSize * page;
  const totalRecords = isRowCount ? rowCount : rows.length;
  const lastRecord = Math.min(firstRecord + pageSize, totalRecords);

  const pageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!isMount && !isSearching) {
      setPaginationModel && setPaginationModel(firstRecord, pageSize);
    } else {
      setPage(0);
    }
  }, [firstRecord, lastRecord, pageSize, totalRecords, isSearching]);

  const handleChange = (event, pg) => {
    event.preventDefault();
    const pageToSet = pg - 1;
    setPage(pageToSet);
  };

  const ReuseLabel = (label) => {
    return (
      <Label
        title={`${label}`}
        fontSize="14px"
        fontWeight="400"
        color={theme.kiosk.subTitleColor}
      />
    );
  };

  const RoundedPagination = () => {
    return (
      <React.Fragment>


        <RoundedPaginationContainer>
          <ShowrecordsContainer>
            {ReuseLabel(`${SHOW}:`)}
            <StyledPageSelector
              value={pageSize}
              style={{ width: 81, borderRadius: 8 }}
              onChange={pageSizeChange}
            >
              {availablePageSizes.map((item, index) => (
                <MenuItem
                  key={`paginatedTable${index}_${Math.random()
                    .toString(16)
                    .slice(2)}`}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </StyledPageSelector>
            {ReuseLabel(RECORDS_PER_PAGE)}
          </ShowrecordsContainer>
          <ShowrecordsContainer>
            {ReuseLabel(`${SHOWING}:`)}
            <Label
              title={`${firstRecord + 1} ${TO} ${lastRecord}`}
              fontSize="14px"
              fontWeight="600"
              color={theme.kiosk.oxfordBlue}
            />
            {ReuseLabel(`${OF}:`)}
            <Label
              title={`${isRowCount ? rowCount : rows.length}`}
              fontSize="14px"
              fontWeight="600"
              color={theme.kiosk.oxfordBlue}
            />
            {ReuseLabel(RECORDS)}
          </ShowrecordsContainer>
          <StyledMuiPagination
            shape="rounded"
            page={page + 1}
            onChange={handleChange}
            count={
              isRowCount
                ? Math.ceil(rowCount / pageSize)
                : Math.ceil(rows.length / pageSize)
            }
            renderItem={(item) => <PaginationItem {...item} />}
          />
        </RoundedPaginationContainer>
      </React.Fragment>
    );
  };

  return (
    <PaginatedGrid
      components={{
        Pagination: RoundedPagination,
        Toolbar: CustomizedToolBar,
      }}
      autoHeight
      paginationModel={{ page: page, pageSize: pageSize }}
      pageSizeOptions={[5, 10, 15, 20]}
      columns={columns}
      rows={rows}
      getRowId={getRowId}
      disableRowSelectionOnClick
      pagination
      rowCount={isRowCount ? rowCount : rows?.length}
      paginationMode={paginationMode || "client"}
      disableColumnMenu
      disableColumnSelector
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={onRowSelectionModelChange}
      editMode={editMode}
      rowModesModel={rowModesModel}
      onRowModesModelChange={onRowModesModelChange}
      onRowEditStop={onRowEditStop}
      processRowUpdate={processRowUpdate}
      slotProps={slotProps}
      keepNonExistentRowsSelected={keepNonExistentRowsSelected}
    />
  );
};

export default PaginatedTable;
