import axios from "axios";
import { eventListFailed, eventListLoading, eventListSuccess, getCameraListError, getCameraListLoading, getCameraListSuccess, getLocationsError, getLocationsLoading, getLocationsSuccess } from "../Slices/eventSlice";

const getEventList = (params) => async (dispatch) => {
  dispatch(eventListLoading(true))
  await axios
    .get(
      "http://103.83.39.56/search_eventlog",
      { params: params }
    )
    .then((response) => {
      dispatch(eventListSuccess(response.data));
      dispatch(eventListLoading(false))

    })
    .catch((err) => {
      dispatch(eventListFailed(err.response));
      dispatch(eventListLoading(false))

    });
};



const getLocationList = () => async (dispatch) => {
  dispatch(getLocationsLoading(true))
  await axios
    .get(
      "http://103.83.39.56/api/get_dropdown_data/2",
    )
    .then((response) => {
      dispatch(getLocationsSuccess(response.data));
      dispatch(getLocationsLoading(false))
    })
    .catch((err) => {
      dispatch(getLocationsError(err.response));
      dispatch(getLocationsLoading(false))
    });
};

const getCameraList = () => async (dispatch) => {
  dispatch(getCameraListLoading(true))
  await axios
    .get(
      "http://103.83.39.56/api/get_dropdown_data/3",

    )
    .then((response) => {
      dispatch(getCameraListSuccess(response.data));
      dispatch(getCameraListLoading(false))

    })
    .catch((err) => {
      dispatch(getCameraListError(err.response));
      dispatch(getCameraListLoading(false))
    });
};

export { getEventList, getLocationList, getCameraList };
