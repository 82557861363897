import { IconButton, styled } from "@mui/material";
import Input from "../Input/index";

export const Root = styled("form")(({ theme, height }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  width: "300px",
  height: "42px",
}));

export const MUIInputBase = styled(Input)(({ theme, disabled, error }) => ({
  flex: 1,
  lineHeight: "24px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderWidth: 1,
      borderRadius: 3,
      borderColor: error
        ? `#D92626 !important`
        : disabled
        ? `#d8ddeb !important`
        : `#2054ff`,
    },
    "&.Mui-focused fieldset": {
      borderWidth: 1,
      borderRadius: 3,
      borderColor: error
        ? `#D92626 !important`
        : disabled
        ? `#d8ddeb !important`
        : `#2054ff !important`,
    },
    "&:not(.Mui-focused)": {
      "&:hover fieldset": {
        borderWidth: 1,
        borderRadius: 3,
        borderColor: `${error ? "#D92626" : "#d8ddeb"} !important`,
      },
      "&.Mui-disabled:hover fieldset": {
        borderColor: `#d8ddeb !important`,
      },
    },
  },
}));
export const MUIIconButton = styled(IconButton)(() => ({
  padding: "8px",
}));
