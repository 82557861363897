import { Label } from '../../components/Label'
import React, { memo } from 'react'
import { StyledTextField } from './inputStyles'
import useTheme from '@mui/material/styles/useTheme'

const Input = ({
  errorMsg,
  error,
  width,
  labelColor,
  type,
  inputModeType,
  height,
  maxLength,
  ...rest
}) => {
  const theme = useTheme()

  const handlePaste = (event) => {
    const pastedData = event.clipboardData?.getData('text')
    if (!pastedData || !maxLength || !event.target) return

    const allowedLength = maxLength - event.target?.value.length
    const truncatedData = pastedData.substring(0, allowedLength)

    const target = event.target
    const selectionStart = target.selectionStart || 0
    const selectionEnd = target.selectionEnd || selectionStart // Handle collapsed selection

    // Use Selection API to replace selected text with pasted content
    target.value =
      target.value.substring(0, selectionStart) +
      truncatedData +
      target.value.substring(selectionEnd)

    // Adjust selection position to the end of pasted content
    target.selectionStart = selectionStart + truncatedData.length
    target.selectionEnd = target.selectionStart

    if (rest?.onChange) {
      rest.onChange(event) // Trigger change event
    }

    event.preventDefault()
  }

  return (
    <div style={{ width: '100%' }}>
      {rest.customLabel && (
        <Label
          style={{ marginBottom: 8 }}
          title={rest.customLabel}
          fontSize={24}
          fontWeight={'500'}
          color={"black"}
        />
      )}
      <StyledTextField
        {...rest}
        style={{
          marginBottom: error ? 16 : 0,
          width: width,
          fontWeight: "500"
        }}
        autoComplete="off"
        InputLabelProps={
          labelColor ? { style: { color: labelColor } } : "#000000"
        }
        height={height}
        fullWidth
        variant="outlined"
        error={error}
        helperText={errorMsg}
        maxRows={rest.multiline ? 6 : undefined}
        multiline={rest.multiline}
        type={type}
        inputProps={{
          inputMode: type === 'number' ? 'numeric' : inputModeType,
          maxLength: maxLength,
        }}
        onPaste={handlePaste}
        onWheel={(e) => (e.target)?.blur()}
      />
    </div>
  )
}

export default memo(Input)
