import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const StyledTextField = styled(TextField)(
  ({ theme, multiline, error, disabled, height, value }) => ({
    background: "white",
    fontFamily: "Poppins !important",
    height: multiline
      ? "" // Do not set height for multiline
      : height !== undefined
        ? `${height}px !important`
        : "42px !important",
    borderRadius: "12px !important",
    "& .MuiInputBase-root": {
      padding: 0,
      paddingLeft: "8px",
      height: "100%",
      fontFamily: "Poppins !important",
      color: "#000000",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#D92626",
      marginTop: error && "5px",
      marginLeft: "0px !important",
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      fontSize: "20px",
      fontWeight: "500",
      color: value ? "#000000" : "#000000",
      padding: multiline ? 5 : 12,
      paddingLeft: 0,
      paddingRight: 8,
      fontFamily: "Poppins !important",
    },
    "& .MuiInputBase-inputMultiline": {
      minHeight: "160px",
      maxHeight: "160px",
      overflowY: "auto !important",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#697496",
      backgroundColor: "#d8ddeb",
      borderRadius: 3,
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
      "-webkit-text-fill-color": `#697496 !important`,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 3,
      "& fieldset": {
        //   borderWidth: 1,
        borderRadius: 3,
        borderColor: error
          ? `#D92626 !important`
          : disabled
            ? `#d8ddeb !important`
            : `#86A3F9`,
      },
      "&.Mui-focused fieldset": {
        //  borderWidth: 1,
        borderRadius: 12,
        borderColor: error
          ? `#D92626 !important`
          : disabled
            ? `${"#d8ddeb"} !important`
            : `#86A3F9 !important`,
      },

      "&:not(.Mui-focused)": {
        "&:hover fieldset": {
          borderWidth: 1,
          borderRadius: 3,
          borderColor: error
            ? `#D92626 !important`
            : disabled
              ? `#d8ddeb !important`
              : `#2054ff !important`,
        },
      },
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
    '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button':
    {
      "-webkit-appearance": "none",
      margin: 0,
    },
    '& input[type="number"]': {
      "-moz-appearance": "textfield",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${"#d8ddeb"} !important`,
    },
  })
);
